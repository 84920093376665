<template>
    <div class="list">
        <jy-table
            class="table"
            :border="true"
            :data="tableData"
            :height="height"
            :max-height="1500"
            :highlight-current-row="false"
            ref="table"
            :row-style="{ height: '45px' }"
            fit
            :cell-style="{ padding: '0' }"
            @select="selectRows"
            @select-all="selectAllRows"
            :default-sort="dsColumn"
            @sort-change="sortChange"
        >
            <jy-table-column fixed width="55" align="center" v-if="selectMode === 'radio'">
                <template slot-scope="scope">
                    <el-radio class="table_radio" v-model="radio" :label="scope.$index" @change.native.prevent="getRadioRow(scope.$index, scope.row)">&nbsp;</el-radio>
                </template>
            </jy-table-column>
            <jy-table-column v-else-if="selectMode === 'check'" type="selection" width="55" align="center"></jy-table-column>
            <jy-table-column fixed v-if="showIndex" align="center" :width="60" type="index" label="序号">
                <template slot-scope="scope">
                    <span>
                        {{ scope.$index + 1 + pageSize * (pageIndex - 1) }}
                    </span>
                </template>
            </jy-table-column>
            <jy-table-column
                align="center"
                v-for="column in realColumns"
                :label="column.title"
                :prop="column.prop"
                :key="column.prop"
                :sortable="column.sortable"
                :min-width="column.minWidth || column.labelWidth || 120"
                :show-overflow-tooltip="true"
            >
                <template slot="header">
                    <span>{{ column.title }}</span>
                    <el-tooltip v-if="column.cTip" effect="dark" placement="top-start">
                        <span slot="content" v-html="column.cTip"></span>
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <template v-if="column.image">
                        <img v-if="scope.row[column.prop]" @click="clickImg($event)" :src="scope.row[column.prop]" style="width: 50px; height: 50px; cursor: pointer" />
                        <span v-else>暂无图片</span>
                    </template>
                    <el-button class="table_inner_button" type="text" size="small" v-else-if="column.clicked" @click="handleEdit(scope.$index, column.index, scope.row)">
                        {{ column.formatter(scope.$index, column.prop, scope.row) }}
                    </el-button>
                    <!-- break:true 根据<br/>做分割 -->
                    <span
                        v-else-if="column.formatter && column.break"
                        :style="{
                            color: (showColor || column.color) && scope.row[column.colorProp || 'color']
                        }"
                        v-html="column.formatter(scope.$index, column.prop, scope.row)"
                    ></span>
                    <!-- formatter -->
                    <span
                        v-else-if="column.formatter"
                        :style="{
                            color: (showColor || column.color) && scope.row[column.colorProp || 'color']
                        }"
                    >
                        {{ column.formatter(scope.$index, column.prop, scope.row) }}
                    </span>
                    <!-- color -->
                    <span
                        v-else
                        :style="{
                            color: (showColor || column.color) && scope.row[column.colorProp || 'color']
                        }"
                    >
                        {{ scope.row[column.prop] | cellValueFilter }}
                    </span>
                </template>
            </jy-table-column>
            <jy-table-column fixed="right" :width="operateWidth" label="操作" v-if="showOpColumn" align="center">
                <template slot-scope="scope">
                    <el-row type="flex" justify="space-between">
                        <el-col v-for="btn in getOpBtns(scope)" :key="btn.index">
                            <el-button
                                :type="btn.type"
                                :disabled="btn.disable"
                                :size="btn.size"
                                class="table_inner_button"
                                :icon="btn.icon"
                                @click="handleEdit(scope.$index, btn.index, scope.row)"
                            >
                                {{ btn.title }}
                            </el-button>
                        </el-col>
                    </el-row>
                </template>
            </jy-table-column>
        </jy-table>
        <jy-pagination
            class="ctbale_pagination"
            v-if="showPager"
            ref="pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            @prev-click="prevClick"
            @next-click="nextClick"
            :current-page="pageIndex"
            :page-sizes="pageRange"
            :page-size="pageSize"
            :layout="layout"
            :total="total"
        ></jy-pagination>
    </div>
</template>
<script type="text/javascript">
    import { filter, cloneDeep, head, pullAllBy, uniqBy } from "lodash";
    import Viewer from "viewerjs";
    import "viewerjs/dist/viewer.css";
    // import { PlTable, PlTableColumn } from "pl-table";
    // import "pl-table/themes/index.css";
    export default {
        props: {
            selectMode: {
                type: String,
                default() {
                    return "none"; // none：不需要选择框  radio：单选 check：多选  且当多选，page组件不显示size
                }
            },
            height: Number,
            columns: {
                type: Array,
                required: true
            },
            data: Array, //每行显示的数据
            // selData: Array, // 选中的项
            selDataIds: Array, // 选中项的ids
            // selRadio: Number, //单选选中的项
            btnList: {
                type: Array,
                required: false
            },
            operateWidth: {
                type: String,
                default: "250"
            },
            showIndex: {
                type: Boolean,
                default: true
            },
            showPager: {
                // 显示分页
                type: Boolean,
                default: true
            },
            total: Number, // 当且仅当showPager为true

            rowKey: {
                type: String,
                default: "id"
            }, //多选情况下作为每行的key值,默认为id,翻页多选的情况下为必传
            flip: {
                type: Boolean,
                default: false
            }, //是否开启翻页多选的功能,默认关闭
            pageRange: {
                //page范围
                type: Array,
                default() {
                    return [10, 20, 50, 100, 150, 200];
                }
            },
            size: {
                type: Number,
                default: 10
            },
            isGenerate: {
                // item.btnList还是btnList
                type: Boolean,
                default: false
            },
            showColor: Boolean, // 整行显示颜色
            dsColumn: Object, // 默认排序行
            layout: {
                type: String,
                default: "total, sizes, prev, pager, next, jumper"
            }
        },

        name: "ctable",
        filters: {
            cellValueFilter: function (val) {
                if (val == null || val == undefined || val == "" || val == "null" || val == "NaN" || (typeof val === "number" && isNaN(val)) || /^\s*$/.test(val)) {
                    return "-";
                } else {
                    return val;
                }
            }
        },
        computed: {
            realColumns() {
                return filter(this.columns, v => {
                    return !v.unShow;
                });
            },
            getRowKey() {
                if (this.flip) {
                    if (this.rowKey) {
                        return this.rowKey;
                    } else {
                        //未指定rowKey
                        throw new Error("请指定列表的rowKey");
                    }
                } else {
                    return this.rowKey;
                }
            },
            getOpBtns() {
                //获取可操作的按钮
                return scope => {
                    const btnProps = {
                        size: "small",
                        type: "text",
                        disabled: false
                    };
                    if (scope.row.btnList && scope.row.btnList.length) {
                        return scope.row.btnList.map(v => {
                            return {
                                ...btnProps,
                                ...v
                            };
                        });
                    } else {
                        if (this.btnList && this.btnList.length) {
                            let list = this.btnList.map(v => {
                                return {
                                    ...btnProps,
                                    ...v
                                };
                            });
                            return list;
                        } else {
                            return [];
                        }
                    }
                };
            },
            showOpColumn() {
                if (this.isGenerate) {
                    return true;
                } else {
                    if (this.btnList && this.btnList.length) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        data() {
            return {
                radio: -1,

                selection: [], // 选中行的数据

                tableData: [], // 数据

                pageIndex: 1,
                pageSize: this.size,
                lock: false // 多选情况下锁，设置选中状态使用
            };
        },
        watch: {
            data: {
                handler() {
                    this.tableData = cloneDeep(this.data);
                    this.initCheckBoxStatus();
                },
                immediate: true,
                deep: true
            },
            selDataIds: {
                handler() {
                    if (this.tableData && this.tableData.length && this.rowKey && this.selDataIds && this.selDataIds.length) {
                        if (this.selectMode === "radio") {
                            this.radio = this.tableData.findIndex(v => v[this.rowKey] === head(this.selDataIds));
                        } else {
                            this.selDataIds.forEach(v => {
                                this.setCheckedRow(v);
                            });
                        }
                    }
                },
                immediate: true
            },
            "tableData.length": function (val) {
                if (val == 0 && this.pageIndex > 1) {
                    //防止删除不翻页
                    this.pageIndex--;
                    this.$emit("update", {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize
                    });
                }
            },
            tableData: {
                handler() {
                    // 重置单选选中
                    if (this.selectMode == "radio") {
                        this.resetSelected();
                    }
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            clickImg(el) {
                new Viewer(el.currentTarget.parentElement.parentElement.parentElement);
                this.$emit("zoom", el);
            },
            reset() {
                // 重置所有
                this.selection = [];
                if (this.$refs.table) {
                    this.$refs.table.clearSelection();
                }
                this.radio = -1;
                this.pageIndex = 1;
            },
            resetSelected() {
                // 重置单选选中
                this.selection = [];
                this.radio = -1;
            },
            getRadioRow(index, item) {
                this.radio = index;
                this.selection = new Array(item);
                this.$emit("select", this.selection);
            },
            // 分页选中时，el-table内部会做一次深拷贝
            selectRows(selectRows, row) {
                if (this.flip) {
                    this.pushItem(row);
                } else {
                    this.selection = selectRows;
                }
                this.$emit("select", this.selection);
            },
            selectAllRows(selection) {
                if (this.flip) {
                    this.pushItems(selection, selection.length);
                } else {
                    this.selection = selection;
                }
                this.$emit("select", this.selection);
            },
            handleEdit(rowIndex, btnIndex, row) {
                this.$emit("cell-click", rowIndex, btnIndex, row);
            },
            handleSizeChange(val) {
                this.pageIndex = 1;
                this.pageSize = val;
                this.$emit("update", {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                });
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.$emit("update", {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                });
            },
            prevClick() {
                this.pageIndex--;
                this.$emit("update", {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                });
            },
            nextClick() {
                this.pageIndex++;
                this.$emit("update", {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                });
            },
            initCheckBoxStatus() {
                //翻页时候选中状态
                if (this.tableData && this.tableData.length && this.selectMode === "check" && this.flip) {
                    this.$nextTick(() => {
                        this.lock = true;
                        if (this.selection && this.selection.length) {
                            this.selection.forEach(row => {
                                this.setCheckedRow(row);
                            });
                        }
                        this.lock = false;
                    });
                }
            },
            pushItem(data) {
                // 往selection中推入数据,单个选择
                if (this.lock) {
                    return false;
                }
                const index = this.selection.findIndex(v => {
                    return v[this.rowKey] == data[this.rowKey];
                });
                if (index != -1) {
                    // 检测到已存在该元素，则删除
                    this.selection.splice(index, 1);
                } else {
                    this.selection.push(data);
                }
            },
            pushItems(array, isSelected) {
                // 全选或者取消全选
                if (this.lock) {
                    return false;
                }
                if (isSelected) {
                    // 选中
                    this.selection = this.uniqArrayByKey(this.selection.concat(this.tableData), this.rowKey);
                } else {
                    // 移除选中
                    this.selection = pullAllBy(this.selection, this.tableData, this.rowKey);
                }
            },
            setCheckedRow(target) {
                // target可以为rowKey，也可以为该行数据
                this.$nextTick(() => {
                    if (this.tableData && this.tableData.length && this.rowKey && target) {
                        let rowId;
                        if (typeof target == "string") {
                            rowId = target;
                        } else {
                            rowId = target[this.rowKey];
                        }
                        this.tableData.forEach(v => {
                            if (v[this.rowKey] === rowId) {
                                this.$refs.table.toggleRowSelection(v, true);
                                // 结束循环
                                return true;
                            }
                        });
                    }
                });
            },
            sortChange({ prop, order }) {
                if (order == "descending") {
                    this.$emit("sort", {
                        sort: "ascending",
                        prop
                    });
                } else if (order == "ascending") {
                    this.$emit("sort", {
                        sort: "descending",
                        prop
                    });
                } else {
                    this.$emit("sort", {
                        sort: "none",
                        prop
                    });
                }
            },
            getPager() {
                return {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                };
            },
            uniqArrayByKey(array, key) {
                return uniqBy(array, key);
            },
            // 增删改查
            query(url, data) {
                this.$http.post(url, data).then(res => {
                    if (res.detail && res.detail.list) {
                        this.tableData = res.data.list;
                    } else {
                        this.tableData = res.detail;
                    }
                });
            },
            delete(url, data, title) {
                if (data.length <= 0) {
                    this.$message.warning({
                        message: "请选择要删除的列",
                        duration: 1000
                    });
                    return;
                }
                this.$confirm(`是否删除这些${title}`, "删除", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.$http.post(url, data).then(() => {
                        this.$message.success({
                            message: "删除成功!",
                            duration: 1000
                        });
                        this.$emit("update");
                    });
                });
            },
            update(url, data) {
                this.$http.post(url, data).then(() => {
                    this.$message.success({
                        message: "更新成功!",
                        duration: 1000
                    });
                    this.$emit("update");
                });
            },
            add(url, data) {
                this.$http.post(url, data).then(() => {
                    this.$message.success({
                        message: "新增成功!",
                        duration: 1000
                    });
                    this.$emit("update");
                });
            }
        }
        //   components: {
        //     PlTable,
        //     PlTableColumn,
        //   },
    };
</script>
<style lang="scss" scoped>
    .list {
        background-color: #ffffff;

        .table {
            overflow: auto;
            font-size: 16px;

            .table_inner_button {
                font-size: 16px;
                padding: 5px;
            }
        }

        .pagination {
            background-color: #ffffff;
            height: 40px;

            .el-pagination {
                float: right;
            }
        }
    }
</style>
<style type="text/css" lang="scss">
    .table_radio {
        .el-radio__label {
            display: none;
        }
    }
</style>
